import { ingredientService } from '@/services/ingredient-service';
import { ingredients } from './state';

export const getIngredients = async () => {
  const response = await ingredientService.getIngredients();

  switch (response.status) {
    case 200: {
      if (response.ok) {
        ingredients.value = response.data;
      }

      return { success: true };
    }
    case 500: {
      return { success: false, error: 'Internal server error' };
    }
    default: {
      if (response.ok) {
        console.warn('getIngredients: Unhandled response', response);
      } else {
        console.error('getIngredients: Unhandled error', response);
      }

      return { success: false, error: 'Something went wrong...' };
    }
  }
};
