import { IngredientItem } from '@/types/ingredients';
import { BaseService } from './base-service';

class IngredientService extends BaseService {
  async getIngredients() {
    return await this.get<IngredientItem[]>('ingredients');
  }
}

export const ingredientService = new IngredientService();
